import { memo, useContext } from 'react';
import { ProspectionDisplayEnum } from '../../../../App/contexts/InputTabsContext';
import { InputTabsContext } from '../../../../App/Layout';
import FolderContainer from '../../../../features/folders/folderContainer';
import SectorContainer from '../../../../features/sectors/components/leftPanelProspection/SectorContainer';
import GenericTabContainer from '../../components/genericTab/GenericTabContainer';
import styles from './prospection.module.scss';

const TAB_LABELS = [ProspectionDisplayEnum.sectors, ProspectionDisplayEnum.folders];

const Prospection = (): JSX.Element => {
  const { prospectionTab, setProspectionTab } = useContext(InputTabsContext);
  return (
    <div>
      <GenericTabContainer
        tabLabels={TAB_LABELS}
        selectedId={prospectionTab}
        onSelect={(id) => setProspectionTab(id)}
        bigger
      />
      <div className={styles.prospection}>
        {prospectionTab === ProspectionDisplayEnum.sectors && <SectorContainer />}

        {prospectionTab === ProspectionDisplayEnum.folders && <FolderContainer />}
      </div>
    </div>
  );
};

export default memo(Prospection);
