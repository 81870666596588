/**
 * Parse api contact to store contact
 * @param {Contact[]} contacts Api contact array
 * @returns {any} parsed contacts
 */
export const contactsApiToStoreParser = (datas: any): Contacts => {
  return datas.map((data: any) => contactApiToStoreParser(data));
};
/**
 * Parse api contact to store contact
 * @param {any} data Api contact
 * @returns {Contact} parsed contact
 */
export const contactApiToStoreParser = (data: any): Contact => {
  const parsedData: Contact = {
    sortValues: { lastName: data.lastName },
    idIri: data['@id'],
    id: parseInt(data['@id'].split('/')[2], 10),
    firstName: data.firstName ?? null,
    lastName: data.lastName ?? null,
    name: null,
    phone: data.phone ?? null,
    email: data.email ?? null,
    address: data.address ?? null,
    description: data.description ?? null,
    allowedToBeContacted: data.allowedToBeContacted,
  };

  if (!parsedData.firstName && !parsedData.lastName && data.name) {
    parsedData.lastName = data.name;
  } else {
    parsedData.name = `${parsedData.firstName} ${parsedData.lastName}`;
  }

  return parsedData;
};

/**
 * Parse store contact to api contact before reuest
 * @param {Contact} contact Store contact
 * @returns {any} parsed contact
 */
export const contactStoreToApiParser = (contact: StudyContactForm | Contact) => {
  const {
    firstName,
    lastName,
    address,
    email,
    phone,
    description,
    allowedToBeContacted,
  } = contact;
  const parsedData = {
    firstName,
    lastName,
    address,
    email,
    phone,
    description,
    allowedToBeContacted,
  };

  return parsedData;
};
