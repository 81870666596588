import { yupResolver } from '@hookform/resolvers/yup';
import { Button } from '@mui/material';
import { cloneDeep } from 'lodash';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from '../../../../App/store';
import DatePickerCustom from '../../../../components/Common/DatePickerCustom';
import NumericFormatFormCustom from '../../../../components/Common/FormComponents/NumericFormatFormCustom';
import SelectFormCustom from '../../../../components/Common/FormComponents/SelectFormCustom';
import { createDateFromString } from '../../../../shared/utils/dateFunctions';
import { getMapState } from '../../../map/mapSlice';
import SectionTitle from '../../SectionTitle';
import { dpeActions, getDpeState } from '../../builtCaracteristicsSlice';
import styles from '../dpe.module.scss';
import { fetchDpePointsThunk } from '../dpeThunk';
import {
  buildingTypes,
  dpeGesItems,
  initialForm,
  validationSchemaDpeForm,
} from './formValidation';

const createDateObject = (value: string | null) => (value ? new Date(value) : null);
interface IDpeAdvancedSearch {
  setloader: Dispatch<SetStateAction<boolean>>;
}

function DpeAdvancedSearch({ setloader }: IDpeAdvancedSearch) {
  const defaultError = { hasError: false, message: '' };
  const { geolocDatas } = useAppSelector(getMapState);
  const { dpeAdvancedSearchFormData, dpeVisible, isRecentOnly } =
    useAppSelector(getDpeState);
  const [createdAtAfter, setCreatedAtAfter] = useState<string | null>(
    dpeAdvancedSearchFormData?.createdAtAfter ?? null
  );
  const [createdAtBefore, setCreatedAtBefore] = useState<string | null>(
    dpeAdvancedSearchFormData?.createdAtBefore ?? null
  );
  const [dateError, setDateError] = useState<{
    hasError: boolean;
    message: string;
  }>(defaultError);
  const dispatch = useAppDispatch();

  const {
    control,
    trigger,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<IDpeAdvancedSearchForm>({
    defaultValues: dpeAdvancedSearchFormData ?? initialForm,
    resolver: yupResolver(validationSchemaDpeForm),
  });

  const submitForm = (data: IDpeAdvancedSearchForm): void => {
    setloader(true);
    dispatch(dpeActions.setGenerateDisplay(true));
    if (!dpeVisible) dispatch(dpeActions.showDpe());
    if (isRecentOnly) dispatch(dpeActions.isRecentHide());
    dispatch(
      fetchDpePointsThunk({
        insee: geolocDatas?.inseeCode as string,
        dpeAdvancedSearch: {
          ...data,
          createdAtAfter,
          createdAtBefore,
        },
      })
    );
    dispatch(
      dpeActions.setDpeAdvancedSearchFormData(
        cloneDeep({
          ...data,
          createdAtAfter,
          createdAtBefore,
        })
      )
    );
  };

  const handleDateChange = (type: 'after' | 'before', e: Date | null) => {
    switch (type) {
      case 'after':
        {
          const db = createDateObject(createdAtBefore);
          if (e && db && e > db) {
            setDateError({
              hasError: true,
              message:
                'La date de début ne doit pas être supérieure à la date de fin',
            });
          } else {
            setCreatedAtAfter(e ? e.toISOString() : null);
            setDateError(defaultError);
          }
        }
        break;
      case 'before':
        {
          const da = createDateObject(createdAtAfter);
          if (e && da && e < da) {
            setDateError({
              hasError: true,
              message:
                'La date de fin ne doit pas être inférieure à la date de début',
            });
          } else {
            setCreatedAtBefore(e ? e.toISOString() : null);
            setDateError(defaultError);
          }
        }
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (!dpeAdvancedSearchFormData) {
      setCreatedAtAfter(null);
      setCreatedAtBefore(null);
      reset(initialForm);
    }
  }, [dpeAdvancedSearchFormData]);

  return (
    <>
      <SectionTitle title="Recherche Avancée DPE" />
      <form onSubmit={handleSubmit(submitForm)} autoComplete="off">
        <div className={styles.row}>
          <div className={styles.field}>
            <div className={styles.label}>Surface du bien mini</div>
            <NumericFormatFormCustom
              control={control}
              name="builtAreaMin"
              error={Boolean(errors.builtAreaMin)}
              errorMsg={errors.builtAreaMin?.message as string}
            />
          </div>
          <div className={styles.field}>
            <div className={styles.label}>Surface du bien maxi</div>
            <NumericFormatFormCustom
              control={control}
              name="builtAreaMax"
              error={Boolean(errors.builtAreaMax)}
              errorMsg={errors.builtAreaMax?.message as string}
            />
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.field}>
            <div className={styles.label}>DPE min</div>
            <SelectFormCustom
              control={control}
              trigger={trigger}
              items={dpeGesItems}
              name="dpeMin"
              displayEmpty
              noValue="Pas de DPE"
              noChoiceDisabled={false}
              error={Boolean(errors.dpeMin)}
              errorMsg={errors.dpeMin?.message as string}
              errorTooltip={errors.dpeMin}
            />
          </div>
          <div className={styles.field}>
            <div className={styles.label}>DPE max</div>
            <SelectFormCustom
              trigger={trigger}
              control={control}
              items={dpeGesItems}
              name="dpeMax"
              displayEmpty
              noValue="Pas de DPE"
              noChoiceDisabled={false}
              error={Boolean(errors.dpeMax)}
              errorMsg={errors.dpeMax?.message as string}
              errorTooltip={errors.dpeMax}
            />
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.field}>
            <div className={styles.label}>GES min</div>
            <SelectFormCustom
              control={control}
              trigger={trigger}
              items={dpeGesItems}
              name="gesMin"
              displayEmpty
              noValue="Pas de GES"
              noChoiceDisabled={false}
              error={Boolean(errors.gesMin)}
              errorMsg={errors.gesMin?.message as string}
              errorTooltip={errors.gesMin}
            />
          </div>
          <div className={styles.field}>
            <div className={styles.label}>GES max</div>
            <SelectFormCustom
              control={control}
              trigger={trigger}
              items={dpeGesItems}
              name="gesMax"
              displayEmpty
              noValue="Pas de GES"
              noChoiceDisabled={false}
              error={Boolean(errors.gesMax)}
              errorMsg={errors.gesMax?.message as string}
              errorTooltip={errors.gesMax}
            />
          </div>
        </div>
        <SelectFormCustom
          control={control}
          items={buildingTypes}
          name="buildingType"
          displayEmpty
          noValue="Toutes Typologies"
          noChoiceDisabled={false}
          error={Boolean(errors.buildingType)}
          errorMsg={errors.buildingType?.message as string}
          errorTooltip={errors.buildingType}
        />
        <div className={styles.datesInputs}>
          <p>DPE réalisés entre</p>
          <div>
            <div className={styles.dateField}>
              <DatePickerCustom
                value={createDateFromString(createdAtAfter)}
                onChange={(e: Date | null) => handleDateChange('after', e)}
                setError={dateError.hasError}
                format="dd-MM-yyyy"
                minDate={new Date('2003-01-01')}
                maxDate={new Date()}
                hasDay
              />
            </div>
            <div className={styles.withSpaces}>et</div>

            <div className={styles.dateField}>
              <DatePickerCustom
                value={createDateFromString(createdAtBefore)}
                onChange={(e: Date | null) => handleDateChange('before', e)}
                setError={dateError.hasError}
                format="dd-MM-yyyy"
                minDate={new Date('2003-01-01')}
                maxDate={new Date()}
                hasDay
              />
            </div>
          </div>
          <p
            className={`${styles.errorBetweenDates} ${
              dateError.hasError ? styles.visible : ''
            }`}
          >
            {dateError.message}
          </p>
        </div>
        <Button type="submit">Rechercher</Button>
      </form>
    </>
  );
}

export default DpeAdvancedSearch;
