import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import styles from './itemActions.module.scss';

function OptionsIcon() {
  return (
    <SettingsOutlinedIcon className={styles.genericIcon} data-cy="options-icon" />
  );
}

export default OptionsIcon;
