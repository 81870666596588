import { Tooltip } from '@mui/material';
import { CalendarOptions, GoogleCalendar } from 'datebook';
import { useAppSelector } from '../../../../../App/store';
import calendar from '../../../../../assets/images/calendar-days-solid.svg';
import arrow from '../../../../../assets/images/horizontal-arrow.svg';
import trait from '../../../../../assets/images/procedure-trait.svg';
import Xicon from '../../../../../assets/images/xicon.png';
import { useStylesFunc } from '../../../../../components/folderAndSectorActions/style';
import { dateFormat } from '../../../../../lib/formats/dataFormat';
import { firstNameAndInitialParser } from '../../../../../utils/jsFunctions';
import { getStudyParamsState } from '../../../slices/studyParamsSlice';
import ProcedureType from './ProcedureType';
import { useRightPanelProcedureContext } from './utils/rightPanelProcedureContext';

interface IProcedureProps {
  procedure: IStudyProcedure;
  updateAllowed: boolean;
}

export default function ProcedureCard({
  procedure,
  updateAllowed,
}: IProcedureProps) {
  const { setDeleteId } = useRightPanelProcedureContext();
  const { type } = useAppSelector(getStudyParamsState);
  const useStyles = useStylesFunc({ isPrimary: true });
  const { classes } = useStyles();

  const config: CalendarOptions = {
    title: procedure.responsable
      ? `${procedure.type?.label} - ${procedure.responsable.firstName ?? ''} ${
          procedure.responsable?.lastName ?? ''
        }`
      : procedure.type?.label,
    description: procedure.comment,
    start: new Date(procedure.date),
    end: new Date(procedure.date),
    attendees: [
      {
        name: `${procedure.responsable?.firstName ?? ''} ${
          procedure.responsable?.lastName ?? ''
        }`,
        email: procedure.responsable?.email,
      },
    ],
  };
  const googleCalendar = new GoogleCalendar(config);

  return (
    <div className="procedure-card">
      <div className="left-block">
        <div className="header">
          <span>{dateFormat(procedure.date)}</span>
          <img src={trait} alt="trait" />
          <span>
            {procedure.responsable
              ? firstNameAndInitialParser(
                  procedure.responsable.firstName ?? null,
                  procedure.responsable.lastName ?? null
                )
              : 'Indéfini'}
          </span>
          {type !== 'sector' && (
            <>
              <img src={arrow} alt="trait" />
              <span>
                {procedure?.contact
                  ? `${procedure.contact.firstName} ${procedure.contact.lastName}`
                  : 'Indéfini'}
              </span>
            </>
          )}

          <ProcedureType type={procedure.type} className="procedure-icon" tooltip />
        </div>
        <p className="body">{procedure.comment}</p>
      </div>
      {updateAllowed && (
        <div className="right-block">
          <Tooltip
            title="Exporter RDV dans mon agenda personnel"
            placement="top"
            arrow
            classes={{
              tooltip: classes.tooltip,
              arrow: classes.arrow,
            }}
          >
            <a href={googleCalendar.render()} target="_blank" rel="noreferrer">
              <img src={calendar} alt="calendar" />
            </a>
          </Tooltip>
          <img
            src={Xicon}
            alt="delete"
            onClick={() => setDeleteId(procedure.idIri)}
          />
        </div>
      )}
    </div>
  );
}
