import { TextField } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import TooltipCustom from '../TooltipCustom';
import styles from '../advancedPlotSearchContainer.module.scss';

interface ISirenNumberInputProps {
  isOwnerEmpty: boolean;
}

function SirenNumberInput({ isOwnerEmpty }: ISirenNumberInputProps) {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <>
      <div className={styles.label}>Numéro SIREN</div>

      <Controller
        control={control}
        name="sirenNumber"
        render={({ field: { value, onChange } }) => (
          <TooltipCustom error={errors.sirenNumber}>
            <TextField
              value={value}
              className={errors.sirenNumber && styles.hasError}
              variant="outlined"
              placeholder="Saisir 9 caractères"
              onChange={onChange}
              InputLabelProps={{
                shrink: true,
              }}
              disabled={!isOwnerEmpty}
              type="text"
              error={Boolean(errors.sirenNumber)}
            />
          </TooltipCustom>
        )}
      />
    </>
  );
}

export default SirenNumberInput;
