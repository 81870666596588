import { Autocomplete, MenuItem, TextField } from '@mui/material';
import { isArray } from 'lodash';
import { makeStyles } from 'tss-react/mui';

interface IAutocompleteFormCustomProps {
  value: AutocompleteItems | ISelectItem | null;
  multiple?: boolean;
  options: AutocompleteItems | SelectItems | [];
  placeholder: string;
  onChange: ChangeEventCustom;
  disabled?: boolean;
  disableClearable?: boolean;
  renderOption?: (props: any, option: any) => JSX.Element;
  getOptionLabel?: (option: any) => string;
}

const useStyles = makeStyles()({
  root: {
    '& .MuiAutocomplete-inputRoot': {
      padding: 0,
    },
  },
});

function AutocompleteFormCustom({
  value,
  options,
  multiple,
  placeholder,
  onChange,
  disabled,
  disableClearable,
  renderOption,
}: IAutocompleteFormCustomProps) {
  const { classes } = useStyles();

  return (
    <Autocomplete
      value={value ?? null}
      multiple={multiple}
      options={
        multiple && isArray(value)
          ? options.filter(
              (elt: IAutocompleteItem) =>
                !value?.find((e: any) => elt.value === e.value)
            )
          : options
      }
      getOptionLabel={(option: IAutocompleteItem) =>
        (option.display as string) ?? ''
      }
      disableClearable={disableClearable}
      onChange={(e, value) => onChange(value)}
      disabled={disabled}
      renderOption={
        renderOption
          ? renderOption
          : (props: any, option: any) => {
              return (
                <MenuItem {...props} value={option.value} key={option.value}>
                  {option.display}
                </MenuItem>
              );
            }
      }
      renderInput={(params) => (
        <TextField {...params} placeholder={placeholder} className={classes.root} />
      )}
    />
  );
}

export default AutocompleteFormCustom;
