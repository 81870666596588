// import '../Prospection.scss';
import { memo } from 'react';
import { useAppSelector } from '../../App/store';
import { getAuthState } from '../../features/auth/authSlice';
import AddIcon from './AddIcon';
import ArrowIcon from './ArrowIcon';
import EditIcon from './EditIcon';
import ExportIcon from './ExportIcon';
import EyeIcon from './EyeIcon';
import GarbageIcon from './GarbageIcon';
import OptionsIcon from './OptionsIcon';
import styles from './itemActions.module.scss';
export interface IIconActionProps<T> {
  type: 'folder' | 'sector' | null;
  item: IFolderSector<T>;
  updateAllowed?: boolean;
}
interface IItemActionProps<T> {
  type: 'folder' | 'sector';
  item: IFolderSector<T>;
  isOpen: boolean;
  isDashboard?: boolean;
}

const ItemActions = <T,>({
  type,
  item,
  isOpen,
  isDashboard,
}: IItemActionProps<T>) => {
  const { userIdIri, isManager, isAdmin } = useAppSelector(getAuthState);
  const updateAllowed = item?.responsable?.idIri === userIdIri || isManager;
  const updateSectorAllowed = isManager || isAdmin;
  return (
    <div className={styles.itemActions}>
      {type === 'folder' ? (
        <>
          {item.parent ? (
            <>
              <GarbageIcon type="folder" item={item} updateAllowed={updateAllowed} />
              <EditIcon type="folder" item={item} updateAllowed={updateAllowed} />
              <ExportIcon item={item} type={null} />
              <EyeIcon type="folder" item={item} />
            </>
          ) : (
            <>
              <GarbageIcon type="folder" item={item} updateAllowed={updateAllowed} />
              <AddIcon type="folder" item={item} updateAllowed={updateAllowed} />
              <ExportIcon item={item} type={null} />
              <EditIcon type="folder" item={item} updateAllowed={updateAllowed} />
            </>
          )}
          <ArrowIcon
            type="folder"
            item={item}
            isOpen={isOpen}
            isDashboard={isDashboard}
          />
        </>
      ) : (
        <>
          {updateSectorAllowed && (
            <div className={styles.options}>
              <div className={styles.optionsIconContainer}>
                <EditIcon
                  type="sector"
                  item={item}
                  updateAllowed={updateSectorAllowed}
                />
                {!item.parent && (
                  <AddIcon
                    type="sector"
                    item={item}
                    updateAllowed={updateSectorAllowed}
                  />
                )}
                <GarbageIcon
                  type="sector"
                  item={item}
                  updateAllowed={updateSectorAllowed}
                />
              </div>

              <OptionsIcon />
            </div>
          )}

          <EyeIcon type="sector" item={item} />
          <ArrowIcon
            type="sector"
            item={item}
            isOpen={isOpen}
            isDashboard={isDashboard}
          />
        </>
      )}
    </div>
  );
};

export default memo(ItemActions);
