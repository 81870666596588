import { makeStyles } from 'tss-react/mui';
import {
  prospectionIconDangerHovered,
  prospectionIconPrimaryHovered,
  white,
} from '../../theme/muiTheme';

export function useStylesFunc({
  isPrimary,
  bgColor,
  textColor,
}: {
  isPrimary?: boolean;
  bgColor?: string;
  textColor?: string;
}) {
  let backgroundColor = prospectionIconDangerHovered;
  let color = white;

  if (isPrimary) backgroundColor = prospectionIconPrimaryHovered;
  if (bgColor) backgroundColor = bgColor;
  if (textColor) color = textColor;

  const useStyles = makeStyles()({
    tooltip: {
      background: backgroundColor,
      radius: '4px',
      padding: '4px 8px',
      fontStyle: 'normal',
      fontWeight: '500',
      fontSize: '12px',
      lineHeight: '14px',
      color: color,
    },
    arrow: {
      '&::before': {
        backgroundColor: '#2B2F59',
      },
    },
  });

  return useStyles;
}
