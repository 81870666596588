import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../App/store';
import { APIStatus } from '../../services/axiosFiles/apiTypes';
import SessionStorageService from '../../services/SessionStorageService';
import { changeCompanyThunk, fetchCompanyThunk } from './companyThunks';
import {
  fetchSubscriptionCitiesThunk,
  fetchSubscriptionDepartmentsThunk,
} from './subscription/subscriptionThunks';

const initialState: CompanyState = {
  companies: [],
  company: { apiStatus: APIStatus.IDLE, result: null },
  companyId: null,
  firm: null,
  companyIdIri: null,
  subscription: null,
};
const companySlice = createSlice({
  name: 'company',
  initialState,
  reducers: {
    setCompanies: (state, action: PayloadAction<TokenCompanies>) => {
      state.companies = action.payload;
    },
    companyInit: (
      state,
      action: PayloadAction<
        Pick<CompanyState, 'companyId' | 'companyIdIri' | 'firm'>
      >
    ) => {
      SessionStorageService.setSelectedCompanyIdIri(action.payload.companyIdIri);

      state.companyIdIri = action.payload.companyIdIri;
      state.companyId = action.payload.companyId;
      state.firm = action.payload.firm;
    },
    reset: () => initialState,
  },
  extraReducers(builder) {
    builder
      .addCase(fetchCompanyThunk.pending, (state) => {
        state.company.apiStatus = APIStatus.PENDING;
      })
      .addCase(
        fetchCompanyThunk.fulfilled,
        (state, action: PayloadAction<Company | null>) => {
          state.company.apiStatus = APIStatus.IDLE;
          state.company.error = undefined;
          if (action.payload) {
            const company = { ...action.payload };
            state.subscription = company.subscription
              ? { ...company.subscription }
              : null;
            delete company.subscription;
            state.company.result = company;
          } else {
            state.company.result = null;
          }
        }
      )
      .addCase(changeCompanyThunk.pending, (state) => {
        state.company.apiStatus = APIStatus.PENDING;
      })
      .addCase(
        changeCompanyThunk.fulfilled,
        (state, action: PayloadAction<TokenCompany>) => {
          SessionStorageService.setSelectedCompanyIdIri(action.payload.idIri);

          state.companyId = action.payload.id;
          state.companyIdIri = action.payload.idIri;
          state.firm = action.payload.firm?.name ?? null;
          state.company = { apiStatus: APIStatus.IDLE, result: null };
        }
      )
      .addCase(changeCompanyThunk.rejected, (state, action: PayloadAction<any>) => {
        state.company.apiStatus = APIStatus.REJECTED;
        state.company.error = action.payload;
      })
      .addCase(fetchSubscriptionDepartmentsThunk.pending, (state) => {
        if (state.subscription) {
          const d = state.subscription.departments;
          d.apiStatus = APIStatus.PENDING;
          d.result = null;
          d.error = undefined;
        }
      })
      .addCase(
        fetchSubscriptionDepartmentsThunk.fulfilled,
        (state, action: PayloadAction<Departments>) => {
          if (state.subscription) {
            const d = state.subscription.departments;
            d.apiStatus = APIStatus.IDLE;
            d.result = action.payload;
            state.subscription.departmentCount = action.payload.length;
            d.error = undefined;
          }
        }
      )
      .addCase(
        fetchSubscriptionDepartmentsThunk.rejected,
        (state, action: PayloadAction<any>) => {
          if (state.subscription) {
            const d = state.subscription.departments;
            d.apiStatus = APIStatus.REJECTED;
            d.result = null;
            d.error = action.payload;
          }
        }
      )
      .addCase(fetchSubscriptionCitiesThunk.pending, (state) => {
        if (state.subscription) {
          const d = state.subscription.cities;
          d.apiStatus = APIStatus.PENDING;
          d.result = null;
          d.error = undefined;
        }
      })
      .addCase(
        fetchSubscriptionCitiesThunk.fulfilled,
        (state, action: PayloadAction<{ cities: Cities; total: number }>) => {
          if (state.subscription) {
            const d = state.subscription.cities;
            d.apiStatus = APIStatus.FULFILLED;
            d.result = action.payload.cities;
            state.subscription.cityCount = action.payload.total;
            d.error = undefined;
          }
        }
      )
      .addCase(
        fetchSubscriptionCitiesThunk.rejected,
        (state, action: PayloadAction<any>) => {
          if (state.subscription) {
            const d = state.subscription.cities;
            d.apiStatus = APIStatus.REJECTED;
            d.result = null;
            d.error = action.payload;
          }
        }
      );
  },
});

export default companySlice.reducer;
export const companyActions = companySlice.actions;
export const getCompanyState = (state: RootState) => state.company;
