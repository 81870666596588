import { Tooltip, TooltipProps, styled, tooltipClasses } from '@mui/material';

const StyledTextErrorTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#d20000',
    fontWeight: 700,
    fontSize: '0.8em',
    maxWidth: 'unset',
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: '#d20000',
  },
}));

export default StyledTextErrorTooltip;
