import DeleteIcon from '@mui/icons-material/Delete';
import { TableCell, TableRow } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../App/store';
import PlotStateBadge from '../../components/Common/StatusElement/PlotStateBadge';
import plotActions from '../../redux/plot/actions';
import { modalsActions } from '../modals/modalsSlice';
import { panelsActions } from '../panels/panelsSlice';
import { procedureActions } from '../procedures/proceduresSlice';
import { procedureEntityNameDisplay } from '../procedures/utils';
import ProcedureType from '../study/components/studyProcedure/rightPanelProcedureBloc/ProcedureType';
import { setStudyParamsThunk } from '../study/services/thunks/studyParamsThunks';
import { getStudyParamsState } from '../study/slices/studyParamsSlice';
import { getStudyTypeFromIdIri } from '../study/utils/getStudyType';

interface AgendaTableBodyRowProps {
  procedure: Procedure;
}
function AgendaTableBodyRow({ procedure }: AgendaTableBodyRowProps) {
  const { idIri } = useAppSelector(getStudyParamsState);
  const dispatch = useAppDispatch();

  const handleCellClick = (procedure: Procedure) => {
    if (idIri !== procedure.entity.idIri) {
      const type = getStudyTypeFromIdIri(procedure.entity.idIri);
      const studyParams: StudyParamsState = {
        type,
        idIri: procedure.entity.idIri,
        noCheckExisting: true,
        managmentLoader: false,
      };

      switch (type) {
        case 'plotStudy':
          // load plot data for map and others panel tabs
          dispatch(
            plotActions.fetchPlot.request({
              data: {
                lon: procedure.entity.lng as number,
                lat: procedure.entity.lat as number,
                department: procedure.entity.department as string,
                pdf: false,
              },
              studyParams,
            })
          );
          break;
        case 'folder':
        case 'sector':
        default:
          studyParams.noCheckExisting = false;
      }

      // open right panel + select 'prospection tab'
      dispatch(setStudyParamsThunk(studyParams));
      dispatch(panelsActions.studyContentShow('prospection'));
    }
  };

  const handleDeleteProcedure = (item: Procedure) => {
    dispatch(procedureActions.procedureForActionSet(item));
    dispatch(modalsActions.procedureDelete(true));
  };

  return (
    <TableRow className="row">
      <TableCell>{procedure.eventDate}</TableCell>
      <TableCell
        className="clickable"
        title="Information parcelle"
        onClick={() => handleCellClick(procedure)}
      >
        {procedureEntityNameDisplay(procedure)}
      </TableCell>
      <TableCell>{procedure.entity.city ?? '-'}</TableCell>
      <TableCell>
        {procedure.contact?.lastName && procedure.contact?.firstName
          ? `${procedure.contact?.firstName ?? ''} ${
              procedure.contact?.lastName ?? ''
            }`
          : procedure.contact?.name}
      </TableCell>
      <TableCell className="col-action">
        {procedure.type ? <ProcedureType type={procedure.type} /> : '-'}
      </TableCell>
      <TableCell>
        {procedure.entity?.status ? (
          <PlotStateBadge status={procedure.entity.status} />
        ) : (
          '-'
        )}
      </TableCell>
      <TableCell className="col-delete">
        <DeleteIcon onClick={() => handleDeleteProcedure(procedure)} />
      </TableCell>
    </TableRow>
  );
}

export default AgendaTableBodyRow;
