import { nanoid } from '@reduxjs/toolkit';
import { isEmpty } from 'lodash';
import { ChangeEvent } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../App/store';
import ItemActions from '../../../../components/folderAndSectorActions/ItemActions';

import { firstNameAndInitialParser } from '../../../../utils/jsFunctions';
import IndividualCircularLoader from '../../../loaders/individualCircularLoader';

import { Checkbox, Tooltip } from '@mui/material';
import { useStylesFunc } from '../../../../components/folderAndSectorActions/style';
import { LEFT_PANEL_PROSPECTION_ENTITY_NAME_LENGTH } from '../../../../shared/constants';
import { stringCut } from '../../../../shared/utils/stringFunctions';
import { getAuthState } from '../../../auth/authSlice';
import {
  displayManagerActions,
  getDisplayManagerState,
} from '../../../displayManager/displayManagerSlice';
import {
  getParentAndChildIdIriTab,
  isEntityDisplayed,
} from '../../../displayManager/utils';
import { sectorsToEntitiesDisplay } from '../../../displayManager/utils/entitiesDisplayParsers';
import { getSectorState } from '../../sectorSlice';
import styles from './sectorContainer.module.scss';

interface ISectorItemProps {
  sector: ISector;
  isSubsector: boolean;
}

const Sector = ({ sector, isSubsector }: ISectorItemProps): JSX.Element => {
  const { entities } = useAppSelector(getDisplayManagerState);
  const { userIdIri } = useAppSelector(getAuthState);
  const { deployedSector } = useAppSelector(getSectorState);
  const useStyles = useStylesFunc({ isPrimary: true });
  const { classes } = useStyles();
  const dispatch = useAppDispatch();

  const handleCheckSectorChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.target.checked
      ? dispatch(
          displayManagerActions.entitiesAdd(
            sectorsToEntitiesDisplay(sector, userIdIri)
          )
        )
      : dispatch(
          displayManagerActions.entitiesRemoveByIdIri(
            getParentAndChildIdIriTab(sector)
          )
        );
  };

  return (
    <>
      {isEmpty(sector) ? (
        <IndividualCircularLoader size={50} />
      ) : (
        <div className={styles.sector}>
          <div
            className={`${styles.sectorHeader} ${
              isSubsector ? styles.subsector : ''
            }`}
            data-cy="sector-header"
          >
            <Checkbox
              color="primary"
              size="small"
              checked={isEntityDisplayed(entities.result, sector.idIri)}
              onChange={handleCheckSectorChange}
            />

            <div className={styles.index}>{sector.indexId}</div>

            <div className={styles.titleContainer}>
              <Tooltip
                title={sector.name}
                placement="top-start"
                classes={{
                  tooltip: classes.tooltip,
                  arrow: classes.arrow,
                }}
              >
                <h3 className={styles.title} data-cy="sector-title">
                  {stringCut(sector.name, LEFT_PANEL_PROSPECTION_ENTITY_NAME_LENGTH)}
                </h3>
              </Tooltip>
              {!isSubsector && (
                <div className={styles.subCount}>{`${sector.subCount} sous secteur${
                  sector.subCount > 1 ? 's' : ''
                }`}</div>
              )}
            </div>
            <div className={styles.responsable}>
              {sector.responsable
                ? firstNameAndInitialParser(
                    sector.responsable?.firstName,
                    sector.responsable?.lastName
                  )
                : ''}
            </div>

            <div className={styles.iconContainer}>
              <ItemActions
                type="sector"
                item={sector}
                isOpen={
                  deployedSector ? sector.idIri === deployedSector.idIri : false
                }
              />
            </div>
          </div>

          <div className={styles.sectorContent}>
            {sector.idIri === deployedSector?.idIri &&
              sector.subs?.map((s) => (
                <Sector key={nanoid()} isSubsector={true} sector={s} />
              ))}
          </div>
        </div>
      )}
    </>
  );
};

export default Sector;
