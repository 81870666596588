import { Tooltip } from '@mui/material';
import './statusElement.scss';
import { useStylesFunc } from '../../folderAndSectorActions/style';
import { isEmpty } from 'lodash';
import { useLocation } from 'react-router';

interface IPlotStateBadgeProps {
  status: nullable<StudyStatus>;
  limitLength?: boolean;
}
const PlotStateBadge = ({ status, limitLength = false }: IPlotStateBadgeProps) => {
  const path = useLocation();
  const useStyles = useStylesFunc({ isPrimary: true });
  const { classes } = useStyles();
  const maxLengthLimit = 9;
  const pathAllowed = path.pathname === '/';

  function limitCharacters(inputString: string) {
    if (inputString.length <= maxLengthLimit) {
      return inputString;
    } else {
      return `${inputString.substring(0, maxLengthLimit)}...`;
    }
  }

  if (isEmpty(status) || status?.id === 0) return null;

  return (
    <Tooltip
      title={limitLength && pathAllowed ? status?.label : ''}
      placement="top"
      disableHoverListener={
        limitLength &&
        pathAllowed &&
        Boolean((status?.label as string).length <= maxLengthLimit)
      }
      classes={{
        tooltip: classes.tooltip,
        arrow: classes.arrow,
      }}
    >
      <div className={`plot-state-badge ${status?.class}`}>
        {limitLength && pathAllowed
          ? limitCharacters(status?.label as string)
          : status?.label}
      </div>
    </Tooltip>
  );
};

export default PlotStateBadge;
