import { isArray } from 'lodash';
import getEntityFromIdIri from '../../../../lib/parsersTools/getEntityFromIdIri';
import getIdFromIdIri from '../../../../lib/parsersTools/getIdFromIdIri';
import { getStudyTypeFromIdIri } from '../getStudyType';

export const genericStudyParser = (
  study: any,
  users: Users,
  folders: Folders,
  statuses: StudyStatuses,
  sectors: Sectors | null
) => {
  try {
    const type = getStudyTypeFromIdIri(study['@id']);

    switch (type) {
      case 'folder':
        return folderStudyParser(study, users, statuses, folders) as IStudy;
      case 'sector':
        return sectorStudyParser(study, users, sectors as Sectors) as IStudy;
      default:
        return plotStudyParser(study, users, statuses, folders) as IStudy;
    }
  } catch (error: any) {
    throw new Error(error.message);
  }
};
export const studyParser = ({
  study,
  users,
  folders,
  sectors,
}: {
  study: any;
  users: Users;
  folders?: IFolder | Folders;
  sectors?: Sectors;
}): IStudy => {
  const type = getStudyTypeFromIdIri(study['@id']);
  let parent: IFolder | ISector | null = null;
  switch (type) {
    case 'folder':
      parent = getEntityFromIdIri<IFolder>(study.parentFolder, folders as Folders);
      break;
    case 'sector':
      parent = getEntityFromIdIri<ISector>(study.parentSector, sectors as Sectors);
      break;
    case 'plotStudy':
    default:
      parent = isArray(folders)
        ? getEntityFromIdIri<IFolder>(study.folder, folders as Folders)
        : (folders as IFolder);
      break;
  }

  return {
    idIri: study['@id'],
    id: getIdFromIdIri(study['@id']),
    responsable: getEntityFromIdIri<User>(study.responsable, users),
    comment: study.comment ?? null,
    parent,
  };
};
export const folderStudyParser = (
  data: any,
  users: Users,
  statuses: StudyStatuses,
  folders: Folders
): IFolderStudy => {
  try {
    const studyBase = studyParser({ study: data, users, folders });

    const parsedFolderStudy: IFolderStudy = {
      ...studyBase,
      feasibilityMatrix: data.feasibilityMatrix ?? null,
      status: getEntityFromIdIri<StudyStatus>(data.status, statuses),
      markerColor: data.markerColor,
      name: data.name,
      priority: data.priority,
      askingPrice: data.askingPrice ?? null,
      expectedRevenue: data.expectedRevenue ?? null,
      buildableArea: data.buildableArea ?? null,
    };

    return parsedFolderStudy;
  } catch (error: any) {
    throw new Error('folder study parser error');
  }
};
export const sectorStudyParser = (
  data: any,
  users: Users,
  sectors: Sectors
): ISectorStudy => {
  try {
    const studyBase = studyParser({ study: data, users, sectors });
    const parsedSectorStudy: ISectorStudy = {
      ...studyBase,
      mailBoxes: data.mailBoxes,
      name: data.name,
    };

    return parsedSectorStudy;
  } catch (error) {
    throw new Error('sector study parser error');
  }
};
export const plotStudiesParser = (
  datas: any[],
  users: Users,
  statuses: StudyStatuses,
  folders: Folders | IFolder
) => datas.map((m) => plotStudyParser(m, users, statuses, folders));

export const plotStudyParser = (
  data: any,
  users: Users,
  statuses: StudyStatuses,
  folders: Folders | IFolder
): IPlotStudy => {
  try {
    const studyBase = studyParser({ study: data, users, folders });

    const parsed: IPlotStudy = {
      ...studyBase,
      parent: studyBase.parent as IFolder,
      lat: data.latitude,
      lng: data.longitude,
      fullPlotId: data.uniquePlotId,
      plotId: data.uniquePlotId?.substring(5) ?? null,
      plotGeometry: data.parcelGeometry ?? null,
      postalCode: data.zipCode,
      department: data.uniquePlotId?.substring(0, 2) ?? null,
      city: data.cityName,
      status: getEntityFromIdIri<StudyStatus>(data.status, statuses),
      area: data.surface ?? null,
      address: data.mapAddress ?? null,
      comment: data.comment ?? null,
      zone: data.zone ?? null,
      zoneChild: data.zoneChild ?? null,
      inseeCode: data.inseeCode ?? null,
      feasibilityMatrix: data.feasibilityMatrix ?? null,
      createdAt: data.createdAt ?? null,
      markerColor: isArray(folders) ? null : folders.markerColor,
      priority: data.priority ?? 0,
      askingPrice: data.askingPrice ?? null,
      expectedRevenue: data.expectedRevenue ?? null,
      buildableArea: data.buildableArea ?? null,
    };

    return parsed;
  } catch (error: any) {
    throw new Error(error);
  }
};
