import { Tooltip } from '@mui/material';
import { PROSPECTION_PRIORITY_LIST } from '../../../constants';
import PriorityIcon from './PriorityIcon';
import styles from './priorityIcon.module.scss';
import { useStylesFunc } from '../../../../components/folderAndSectorActions/style';

function getColorLevel(level: number | string) {
  const lvl = typeof level === 'string' ? parseInt(level, 10) : level;
  let color: { bgColor: string; textColor: string } = { bgColor: '', textColor: '' };

  if (lvl === 1) color = { bgColor: '#4fd211', textColor: '#000' };
  if (lvl === 2) color = { bgColor: '#d29c11', textColor: '#fff' };
  if (lvl === 3) color = { bgColor: '#d20000', textColor: '#fff' };

  return color;
}

function PriorityIconWithTooltip(props: IPriorityIconProps) {
  const levelColor = getColorLevel(props.level);
  const useStyles = useStylesFunc(levelColor);
  const { classes } = useStyles();

  if (props.level === null || props.level === 0) return null;

  const label = PROSPECTION_PRIORITY_LIST.find(
    (f) => f.value === props.level
  )?.display;

  return (
    <Tooltip
      title={`Priorité ${label}`}
      placement="top"
      classes={{
        tooltip: classes.tooltip,
        arrow: classes.arrow,
      }}
    >
      <div className={styles.priorityIconWithTooltip}>
        <PriorityIcon {...props} />
      </div>
    </Tooltip>
  );
}

export default PriorityIconWithTooltip;
