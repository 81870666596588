export function sortEntityByKey<T>(
  array: T[],
  key: keyof T,
  ascending: boolean = true
): T[] {
  return array.slice().sort((a, b) => {
    const valueA = a[key];
    const valueB = b[key];

    if (valueA === valueB) {
      return 0;
    }

    if (ascending) {
      return valueA < valueB ? -1 : 1;
    } else {
      return valueA > valueB ? -1 : 1;
    }
  });
}
