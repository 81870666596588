import { Tooltip } from '@mui/material';

import { useAppSelector } from '../../App/store';
import { getMapPluginsUrbaToolbarState } from '../../features/map/leaflet/plugins/mapPluginSlice';
import { ToolbarTypeEnum } from '../../features/map/leaflet/plugins/toolbars/urbaToolbar/types/ToolbarBtnEnums';
import { useStylesFunc } from '../folderAndSectorActions/style';

interface ITooltipDisableMapToolbarProps {
  children: React.ReactElement;
}

function TooltipDisableMapToolbar({ children }: ITooltipDisableMapToolbarProps) {
  const useStyles = useStylesFunc({ isPrimary: true });
  const { classes } = useStyles();
  const { activeToolbar } = useAppSelector(getMapPluginsUrbaToolbarState);
  const mapToolbar =
    activeToolbar !== ToolbarTypeEnum.DRAW && activeToolbar !== null;

  return (
    <Tooltip
      disableHoverListener={!mapToolbar}
      title={'Veuillez désactiver les outils de la carte'}
      placement="top"
      arrow
      classes={{
        tooltip: classes.tooltip,
        arrow: classes.arrow,
      }}
    >
      {children}
    </Tooltip>
  );
}

export default TooltipDisableMapToolbar;
