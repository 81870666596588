import { memo, useEffect, useState } from 'react';
import { useAppSelector } from '../../../../App/store';
import { getAuthState } from '../../../../features/auth/authSlice';
import { getMapState } from '../../../../features/map/mapSlice';
import PluOtherContainer from '../../../../features/plu/PluOther/PluOtherContainer';
import PluLegend from '../../../../features/plu/pluLegend/PluLegend';

import { APIStatus } from '../../../../services/axiosFiles/apiTypes';
import usePLU from '../../../../shared/hooks/usePLU';
import DeniedAccess from '../../../noRights/DeniedAccess';
import NoCity from '../../../noRights/NoCity';
import '../../styles/urbaEnvContent.scss';
import ArrowTab from '../ArrowTab/ArrowTab';
import { pluOtherRules } from '../../../../features/plu/pmTiles/constants';
import { isEmpty } from 'lodash';
import { nanoid } from '@reduxjs/toolkit';

const Urbanisme = (): JSX.Element => {
  const { pluOthers, pluOthersApiStatus } = usePLU();
  const [selected, setSelected] = useState<number>(1);
  const { deniedZone } = useAppSelector(getAuthState);
  const { geolocDatas } = useAppSelector(getMapState);

  // Use a Set to store unique categories from the filtered data
  const displayCategories = Array.from(
    new Set(
      // Filter pluOtherRules based on conditions from pluOthers
      pluOtherRules
        .filter((elt) =>
          // Check if there is a matching entry in pluOthers with the same cat and subCat (catChild)
          pluOthers?.find((e) => e.cat === elt.cat && e.subCat === elt.catChild)
        )
        // Map the filtered items to their category property
        .map((item) => item.category)
    )
  );

  const handleChangeSelectedTab = (id: number): void => {
    setSelected(id);
  };

  useEffect(() => {
    if (pluOthersApiStatus === APIStatus.PENDING) {
      setSelected(1);
    }
  }, [pluOthersApiStatus]);

  if (deniedZone) {
    return <DeniedAccess />;
  }

  if (!geolocDatas?.city) {
    return <NoCity />;
  }

  return (
    <div className="urba-env-content">
      <div className="tabs-wrapper">
        <ArrowTab
          label1="PLU"
          label2="Zonage"
          onClick={() => handleChangeSelectedTab(1)}
          selected={selected === 1}
        />

        {!isEmpty(displayCategories) &&
          displayCategories.map((elt, i) => (
            <ArrowTab
              key={nanoid()}
              label1={elt}
              onClick={() => handleChangeSelectedTab(i + 2)}
              selected={selected === i + 2}
            />
          ))}
      </div>

      <div className="content-wrapper">
        {selected === 1 && <PluLegend />}
        {!isEmpty(displayCategories) &&
          pluOthers &&
          // Iterate over each category in displayCategories
          displayCategories.map((category, i) => (
            <>
              {selected === i + 2 && ( // Check if the current index + 2 equals the selected value
                <PluOtherContainer
                  key={nanoid()}
                  data={pluOthers.filter((elt) =>
                    // Check if there is a matching entry in pluOtherRules
                    pluOtherRules?.find(
                      (e) =>
                        e.cat === elt.cat &&
                        e.catChild === elt.subCat &&
                        e.category === category
                    )
                  )}
                />
              )}
            </>
          ))}
      </div>
    </div>
  );
};

export default memo(Urbanisme);
