import { AxiosRequestConfig } from 'axios';
import { isEmpty } from 'lodash';
import { paramsNotFound } from '../../../services/axiosFiles/axiosUtils';
import { fetchDatasNew } from '../../../services/axiosFiles/genericCrud';
import { plotStudiesParser } from '../../study/utils/parsers/studyParser';

interface IFetchFolderPlotStudiesProps {
  folder: IFolder;
  config?: AxiosRequestConfig;
  users: Users | null;
  statuses: StudyStatuses | null;
  customQuery?: string;
}
export const fetchFolderPlotStudies = async ({
  folder,
  config,
  users,
  statuses,
  customQuery = '',
}: IFetchFolderPlotStudiesProps): Promise<PlotStudies> => {
  try {
    if (folder && users && statuses) {
      const endpoint = folder.idIri + '/plot_studies' + customQuery;

      // fetch plot studies and parse them
      const result = await fetchDatasNew({ endpoint, config });
      const psArray = result['hydra:member'];

      // if no psArray, no error and return empty array
      if (isEmpty(psArray)) return [];

      // parse response with geom = null
      const parsedResponse = plotStudiesParser(psArray, users, statuses, folder);

      return parsedResponse;
    } else {
      return Promise.reject(paramsNotFound());
    }
  } catch (error) {
    return Promise.reject(error);
  }
};
