import { memo, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { appActions } from '../../App/appSlice';
import useRightPanel from '../../App/hooks/useRightPanel';
import { useAppDispatch, useAppSelector } from '../../App/store';
import { getAuthState } from '../../features/auth/authSlice';
import '../filterSortBloc/filterSortBloc.scss';
import { dashboardTabsValues } from '../Account/constant';
import './dashboardTabs.scss';

interface IDashboardTabsProps {
  selectedTab: DashboardTab;
}

const DashboardTabs = ({ selectedTab }: IDashboardTabsProps): JSX.Element => {
  const { isManager } = useAppSelector(getAuthState);
  const { closeRightPanel, rightPanelDisplay } = useRightPanel();
  const dispatch = useAppDispatch();

  const handleTabChange = (tab: DashboardTab) => {
    if (rightPanelDisplay) {
      closeRightPanel();
    }
    dispatch(appActions.setDashboardTab(tab));
  };

  useEffect(() => {
    return () => {
      closeRightPanel();
    };
  }, []);

  return (
    <div className={`dashboard-tabs`}>
      {dashboardTabsValues
        .filter((f) => (isManager ? f : f.role.includes('user')))
        .map((el) => {
          return (
            <div
              key={uuidv4()}
              onClick={() => handleTabChange(el.id as DashboardTab)}
              className={`dashboard-tab ${selectedTab === el.id ? 'selected' : ''}`}
            >
              {el.libelle}
            </div>
          );
        })}
    </div>
  );
};

export default memo(DashboardTabs);
