import { useContext, useEffect, useState } from 'react';

import { getAppState } from '../../../../../App/appSlice';
import createCtx from '../../../../../App/contexts/GenenricStateContext';
import { useAppSelector } from '../../../../../App/store';
import { studyContactContext } from '../../../../../components/RightPanelModal/TabsContent/bodys/Prospection';
import AddFormDisplay from '../../../../../components/RightPanelModal/TabsContent/bodys/components/prospection/AddFormDisplay';
import { getCompanyState } from '../../../../company/companySlice';
import ContactSearchModal from '../../../../contacts/contactSearchModal';
import createContactCtx from '../../../../contacts/contactSearchModal/contactContext';
import { getModalsState } from '../../../../modals/modalsSlice';
import { fetchStudyContacts } from '../../../services/fetchStudyContacts';
import ContactForm from './StudyContactForm';
import StudyContactList from './StudyContactList';
import styles from './contact.module.scss';

interface IContactContentProps {
  studyIdIri: string | null;
  updateAllowed: boolean;
  onScroll: (offset: number) => void;
}
const [ctx, OpenFormProvider] = createCtx(false);
export const ForceOpenContext = ctx;

const [contactCtx, ContactProvider] = createContactCtx();
export const contactContext = contactCtx;

function StudyContactContent({
  studyIdIri,
  updateAllowed,

  onScroll,
}: IContactContentProps): JSX.Element {
  const { state, update } = useContext(studyContactContext);
  const [studyContactToUpdate, setStudyContactToUpdate] =
    useState<IStudyContact | null>(null);
  const { contactSearch } = useAppSelector(getModalsState);
  const { companyId } = useAppSelector(getCompanyState);
  const { contactRoles } = useAppSelector(getAppState);

  const getContacts = async () => {
    try {
      const sContacts = await fetchStudyContacts(
        studyIdIri,
        companyId,
        contactRoles.result
      );
      update(sContacts);
    } catch (error) {
      update([]);
    }
  };

  useEffect(() => {
    if (studyIdIri) {
      getContacts();
    } else {
      update(null);
    }
  }, [studyIdIri]);

  const handleStudyContactToUpdate = (contact: IStudyContact) => {
    setStudyContactToUpdate(contact);
  };
  const handleClick = () => {
    setStudyContactToUpdate(null);
  };

  return (
    <div className={styles.contactContent}>
      <OpenFormProvider>
        <ContactProvider>
          <StudyContactList
            studyContactList={state}
            onSelect={handleStudyContactToUpdate}
            updateAllowed={updateAllowed}
          />

          {updateAllowed && (
            <>
              <AddFormDisplay
                labelOpen="Ajouter un contact"
                labelClose="Masquer le formulaire"
                ForceOpenContext={ForceOpenContext}
                scroll={onScroll}
                onClick={handleClick}
              >
                <ContactForm studyContactToUpdate={studyContactToUpdate} />
              </AddFormDisplay>
              {contactSearch && <ContactSearchModal />}
            </>
          )}
        </ContactProvider>
      </OpenFormProvider>
    </div>
  );
}

export default StudyContactContent;
