import { memo, useState } from 'react';
import { useAppDispatch } from '../../../App/store';
import useFolder from '../../../features/folders/useFolder';
import { createPlotStudyThunk } from '../../../features/study/services/thunks/studyThunks';
import AutocompleteFormCustom from '../../../components/Common/FormComponents/AutocompleteFormCustom';
import { MenuItem } from '@mui/material';
import SelectFolderItemCustom from '../../../components/Common/FormComponents/SelectFolderItemCustom/Index';

interface IFolderAutocompleteProps {
  isPlotStudy: boolean;
  parent: IFolder | null;
  onUpdate?: (params: UpdateStudyManagmentProps) => void;
  onUpdateMultiPlot?: React.Dispatch<React.SetStateAction<IFolder | null>>;
  disabled?: boolean;
}

const FolderAutocomplete = ({
  parent,
  isPlotStudy,
  onUpdate,
  onUpdateMultiPlot,
  disabled,
}: IFolderAutocompleteProps) => {
  const { foldersForSelect, getFolderByIdIriFromList } = useFolder({ noLoad: true });
  const dispatch = useAppDispatch();
  const [value, setValue] = useState<ISelectItem | null>(
    foldersForSelect.find((elt) => elt.value === parent?.idIri) ?? null
  );

  const handleCreate = (folder: IFolder): void => {
    dispatch(
      createPlotStudyThunk({
        folder,
      })
    );
  };

  const handleFolderChange = (data: ISelectItem): void => {
    setValue(data);

    const selectedFolderIdIri = data.value as string;
    const p = getFolderByIdIriFromList(selectedFolderIdIri);

    if (parent && onUpdate) {
      onUpdate({ parent: p });
    } else if (onUpdateMultiPlot) {
      onUpdateMultiPlot(p);
    } else {
      handleCreate(p);
    }
  };
  // need to refacto value state, useEffect didn't update the state value for AutocompleteFormCustom and pass null
  return (
    <AutocompleteFormCustom
      options={foldersForSelect}
      value={
        value ?? foldersForSelect.find((elt) => elt.value === parent?.idIri) ?? null
      }
      placeholder="Choisir un dossier"
      onChange={(value: any) => {
        handleFolderChange(value);
      }}
      disabled={!isPlotStudy || disabled}
      disableClearable
      renderOption={(props: any, option: any) => {
        return (
          <MenuItem {...props} value={option.value} key={option.value}>
            <SelectFolderItemCustom
              value={option.display as string}
              isSubfolder={option.isSubfolder}
            />
          </MenuItem>
        );
      }}
    />
  );
};

export default memo(FolderAutocomplete);
