import StyledTextErrorTooltip from '../../../shared/muiStyledComponents/TextErrorTooltip';

interface ITooltipCustomProps {
  error: any;
  children: React.ReactElement<any, any>;
}

function TooltipCustom({ error, children }: ITooltipCustomProps) {
  return (
    <StyledTextErrorTooltip
      disableFocusListener
      disableHoverListener
      disableTouchListener
      open={Boolean(error)}
      title={error?.message ?? ''}
      placement="top"
      arrow
    >
      {children}
    </StyledTextErrorTooltip>
  );
}

export default TooltipCustom;
