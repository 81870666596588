import {
  changeApiKeyForStoreKey,
  mapObjectParser,
} from '../../services/common/parserCommon';

const pluOtherMapping = {
  cat: 'cat',
  sub_cat: 'subCat',
  master_cat: 'masterCat',
  libelle: 'libelle',
  mapbox_url: 'mapboxUrl',
};

export const zonesAndZoneChildsApiToStore = (datas: any) => {
  const filteredDatas = { ...datas };

  delete filteredDatas.city_name;
  delete filteredDatas.city_zipcode;

  const zones = Object.keys(filteredDatas);
  const zoneChilds: { [x: string]: string[] } = filteredDatas;

  return {
    zones,
    zoneChilds: zoneChilds,
    city: datas.city_name,
    postalCode: datas.city_zipCode,
  };
};
export function pluOthersApiToStoreParser(data: any): PluOthers {
  const parsedData = mapObjectParser(data, pluOtherApiToStoreParser);

  return parsedData;
}
export function pluOtherApiToStoreParser(data: any): PluOther {
  const temp = changeApiKeyForStoreKey<PluOther>(data, pluOtherMapping);
  temp.displayed = false;

  return temp;
}

export function functionPluUrlParser(datas: any): PluUrls {
  try {
    if (!datas) throw new Error('No data to parse');

    const status =
      datas.plu_status?.status === 'CC'
        ? 'Carte Communale'
        : datas.plu_status.status;

    const parsed: PluUrls = {
      mapboxUrl: datas.mapbox_url ?? null,
      pmtilesUrl: datas.pmtiles_url ?? null,
      lastHumanCheck: datas.last_human_check,
      botIntegrationDate: datas.bot_integration_date,
      noUrl: !datas.mapbox_url && !datas.pmtiles_url,
      displayed: false,
      pluStatus: status ?? null,
    };

    return parsed;
  } catch (error: any) {
    throw new Error(error);
  }
}
