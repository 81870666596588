import { useEffect } from 'react';
import { getAppState } from '../../../../../App/appSlice';
import { useAppSelector } from '../../../../../App/store';
import { getCompanyState } from '../../../../company/companySlice';
import { getUsersState } from '../../../../users/usersSlice';
import { fetchStudyProcedures } from '../../../services/fetchStudyProcedures';
import ProcedureCardMap from './ProcedureCardMap';
import { useRightPanelProcedureContext } from './utils/rightPanelProcedureContext';

interface IProcedureListProps {
  studyIdIri: string | null;
  updateAllowed: boolean;
}

function StudyProcedureList({
  studyIdIri,
  updateAllowed,
}: IProcedureListProps): JSX.Element {
  const { companyId } = useAppSelector(getCompanyState);
  const { users } = useAppSelector(getUsersState);
  const { contactRoles, procedureTypes } = useAppSelector(getAppState);
  const { setStudyProcedureList, studyProcedureList } =
    useRightPanelProcedureContext();

  const getStudyProcedures = async () => {
    const ps = await fetchStudyProcedures(
      studyIdIri,
      companyId,
      users,
      contactRoles.result,
      procedureTypes.result
    );
    setStudyProcedureList(ps);
  };

  useEffect(() => {
    if (studyIdIri) {
      getStudyProcedures();
    } else {
      setStudyProcedureList(null);
    }
  }, [studyIdIri]);

  return (
    <div className="procedure-list">
      <ProcedureCardMap
        updateAllowed={updateAllowed}
        studyProcedureList={studyProcedureList}
      />
    </div>
  );
}

export default StudyProcedureList;
