import { TableBody, TableCell, TableRow } from '@mui/material';
import { nanoid } from '@reduxjs/toolkit';
import { isEqual } from 'lodash';
import { memo, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../App/store';
import PaginationCustom from '../../../../components/Common/PaginationCustom/PaginationCustom';
import PlotStateBadge from '../../../../components/Common/StatusElement/PlotStateBadge';
import TableRowFullColumn from '../../../../components/Common/TableRowFullColumn';
import { genericEntitySort } from '../../../../lib/sort/genericEntitySort';

import plotActions from '../../../../redux/plot/actions';

import PriorityIconWithTooltip from '../../../../shared/components/icons/priorityIcon/PriorityIconWithTooltip';
import { spacedNumbers } from '../../../../utils/jsFunctions';
import { panelsActions } from '../../../panels/panelsSlice';
import { setStudyParamsThunk } from '../../../study/services/thunks/studyParamsThunks';
import { getStudyParamsState } from '../../../study/slices/studyParamsSlice';

interface IContactPlotStudiesContainerProps {
  contactPlotStudies: SortablePlotStudies;
  order: Order;
  sortKey: string;
}

function ContactPlotStudiesContainer({
  contactPlotStudies,
  order,
  sortKey,
}: IContactPlotStudiesContainerProps) {
  const [indexPage, setIndexPage] = useState<number>(0);
  const [itemsPerPage, setItemsPerPage] = useState<number>(5);
  const { idIri } = useAppSelector(getStudyParamsState);
  const dispatch = useAppDispatch();

  const hasPagination = (contactPlotStudies.length ?? 0) > itemsPerPage;

  const handleClick = async (cps: IPlotStudy) => {
    if (cps.lng && cps.lat && cps.postalCode && idIri !== cps.idIri) {
      const studyParams: StudyParamsState = {
        type: 'plotStudy',
        idIri: cps.idIri,
        noCheckExisting: true,
        managmentLoader: true,
      };

      // load plot data for map and others panel tabs
      dispatch(setStudyParamsThunk(studyParams));
      dispatch(panelsActions.studyContentShow('prospection'));
      dispatch(
        plotActions.fetchPlot.request({
          data: {
            lon: cps.lng,
            lat: cps.lat,
            department: cps.department,
            pdf: false,
          },
          studyParams,
        })
      );
    }
  };

  const handleChangeIndex = (index: number): void => {
    setIndexPage(index);
  };

  const handleItemsPerPageChange = (e: ChangeEventCustom) => {
    setItemsPerPage(e.target.value);
  };

  return (
    <TableBody>
      {genericEntitySort<SortablePlotStudy>(contactPlotStudies, sortKey, order)
        ?.filter((f, i: number) => i >= indexPage && i < indexPage + itemsPerPage)
        .map((elt) => (
          <TableRow key={nanoid()} className="plot-study-row row">
            <TableCell
              onClick={() => handleClick(elt.entity)}
            >{`${elt.entity.city} (${elt.entity.postalCode})`}</TableCell>
            <TableCell
              onClick={() => handleClick(elt.entity)}
              title="Informations parcelle"
              className="clickable"
            >
              {elt.entity.plotId}
            </TableCell>
            <TableCell>{elt.entity.parent?.name as string}</TableCell>
            <TableCell onClick={() => handleClick(elt.entity)}>
              {elt.entity.area ? spacedNumbers(elt.entity.area) + ' m²' : '-'}
            </TableCell>
            <TableCell>{`${elt.entity.responsable?.firstName} ${elt.entity.responsable?.lastName}`}</TableCell>
            <TableCell onClick={() => handleClick(elt.entity)}>
              <PlotStateBadge status={elt.entity.status} />
            </TableCell>
            <TableCell onClick={() => handleClick(elt.entity)}>
              <PriorityIconWithTooltip level={elt.entity.priority} />
            </TableCell>
          </TableRow>
        ))}
      {hasPagination && (
        <TableRowFullColumn colSpan={6} className="pagination-row">
          <PaginationCustom
            itemPerPageLabel="Parcelles par page"
            elementsQty={contactPlotStudies?.length || 0}
            itemsPerPage={itemsPerPage}
            onItemsPerPageChange={handleItemsPerPageChange}
            onMinIndexTab={handleChangeIndex}
          />
        </TableRowFullColumn>
      )}
    </TableBody>
  );
}

function propsAreEqual(
  p: Readonly<IContactPlotStudiesContainerProps>,
  n: Readonly<IContactPlotStudiesContainerProps>
) {
  let result = true;
  //if sort and search is updated
  if (p.order !== n.order || p.sortKey !== n.sortKey) {
    result = false;
  } else if (!isEqual(p.contactPlotStudies, n.contactPlotStudies)) {
    result = false;
  }

  return result;
}
export default memo(ContactPlotStudiesContainer, propsAreEqual);
