import store from '../../../App/store';
import { paramsNotFound } from '../../../services/axiosFiles/axiosUtils';
import { updateEntity } from '../../../services/axiosFiles/genericCrud';
import { updateContact } from '../../contacts/services/contactRequests';
import { studyContactApiToStoreParser } from '../utils/parsers/studyContactParser';
async function updateStudyContact(
  body: StudyContactForm,
  studyContactToUpdate: IStudyContact | null
) {
  try {
    const state = store.getState();
    const contactRoles = state.app.contactRoles.result;
    const { idIri, type } = state.studyParams;

    if (idIri && studyContactToUpdate && type && contactRoles && body) {
      const b: any = {
        ...body,
        studyContactIdIri: studyContactToUpdate.idIri,
        contactIdIri: studyContactToUpdate.contact.idIri,
      };
      await updateContact(b.contactIdIri, body);

      const studyContactResult = await updateEntity({
        idIri: studyContactToUpdate.idIri,
        body: {
          contact: studyContactToUpdate.contact.idIri,
          [type]: idIri,
          contactRole: body.roleIdIri,
        },
      });

      const parsedStudyContact = studyContactApiToStoreParser(
        studyContactResult,
        contactRoles
      );

      return parsedStudyContact;
    } else {
      return Promise.reject(paramsNotFound('updateStudyContact'));
    }
  } catch (error) {
    return Promise.reject(error);
  }
}

export default updateStudyContact;
