import { nanoid } from '@reduxjs/toolkit';
import { useContext } from 'react';
import { contactContext } from '../../study/components/studyContact/rightPanelContactBloc/StudyContactContent';
import ContactRow from './ContactRow';
import { contactHeaderRowModal } from './constants';

interface IContactTableProps {
  onCloseModal: () => void;
  contacts: Contacts;
}
function ContactTable({ onCloseModal, contacts }: IContactTableProps) {
  const { setActiveContact } = useContext(contactContext);

  const handleRowClick = (contact: Contact) => {
    setActiveContact(contact);
    onCloseModal();
  };

  return (
    <table>
      <thead>
        <tr>
          {contactHeaderRowModal?.map((elt) => {
            return <th key={nanoid()}>{elt.title}</th>;
          })}
        </tr>
      </thead>

      <tbody>
        {contacts?.map((contact: any) => (
          <ContactRow key={nanoid()} contact={contact} onClick={handleRowClick} />
        ))}
      </tbody>
    </table>
  );
}

export default ContactTable;
