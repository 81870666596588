import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import { useAppDispatch, useAppSelector } from '../../App/store';
import { getAuthState } from '../../features/auth/authSlice';
import { foldersActions } from '../../features/folders/foldersSlice';
import { DrawActionEnum } from '../../features/map/leaflet/plugins/drawPlugin/types/drawActionEnum';
import {
  getMapPluginsUrbaToolbarState,
  mapPluginsActions,
} from '../../features/map/leaflet/plugins/mapPluginSlice';
import { modalsActions } from '../../features/modals/modalsSlice';
import { sectorActions } from '../../features/sectors/sectorSlice';
import { IIconActionProps } from './ItemActions';
import TooltipIcon from './TooltipIcon';
import styles from './itemActions.module.scss';

function AddIcon<T>({ type, item, updateAllowed }: IIconActionProps<T>) {
  const dispatch = useAppDispatch();
  const { activeToolbar } = useAppSelector(getMapPluginsUrbaToolbarState);
  const { isManager, isAdmin } = useAppSelector(getAuthState);

  const handleClick = () => {
    if (type === 'folder') {
      dispatch(foldersActions.subFolderParentSet((item as IFolder).idIri));
      dispatch(modalsActions.folderEdit(true));
    } else {
      if (activeToolbar === null && (isAdmin || isManager)) {
        dispatch(sectorActions.subSectorParentSet(item as ISector));
        dispatch(mapPluginsActions.drawStart(DrawActionEnum.DRAW_SECTOR));
      }
    }
  };

  return (
    <>
      {updateAllowed && (
        <TooltipIcon
          action={
            type === 'folder' ? 'Ajouter sous-dossier' : 'Ajouter sous-secteur'
          }
        >
          <FolderOpenIcon
            className={`${styles.genericIcon} ${styles.add} ${
              type !== 'folder' && styles.red
            }`}
            onClick={handleClick}
          />
        </TooltipIcon>
      )}
    </>
  );
}

export default AddIcon;
