import { nanoid } from '@reduxjs/toolkit';
import GenericTab from './GenericTab';
import styles from './genericTab.module.scss';

interface IProps<T> {
  tabLabels: T[];
  selectedId: T;
  onSelect: (id: T) => void;
  bigger?: boolean;
}

function GenericTabContainer<T>({
  tabLabels,
  selectedId,
  onSelect,
  bigger,
}: IProps<T>) {
  return (
    <div className={styles.genericTabContainer}>
      {tabLabels.map((m) => (
        <GenericTab
          dataCy="generic-tab"
          key={nanoid()}
          label={m}
          selected={selectedId === m}
          onSelect={() => onSelect(m)}
          bigger={bigger}
        />
      ))}
    </div>
  );
}

export default GenericTabContainer;
