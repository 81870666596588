import DeleteIcon from '@mui/icons-material/Delete';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { nanoid } from '@reduxjs/toolkit';
import { MouseEvent, useState } from 'react';
import usePloStudyStatus from '../../../App/hooks/usePlotStudyStatus';
import { useAppDispatch, useAppSelector } from '../../../App/store';
import { modalsActions } from '../../../features/modals/modalsSlice';
import { procedureActions } from '../../../features/procedures/proceduresSlice';
import useProcedures from '../../../features/procedures/useProcedures';
import { procedureEntityNameDisplay } from '../../../features/procedures/utils';
import plotActions from '../../../redux/plot/actions';

import { panelsActions } from '../../../features/panels/panelsSlice';
import ProcedureType from '../../../features/study/components/studyProcedure/rightPanelProcedureBloc/ProcedureType';
import { setStudyParamsThunk } from '../../../features/study/services/thunks/studyParamsThunks';
import { getStudyParamsState } from '../../../features/study/slices/studyParamsSlice';
import { getStudyTypeFromIdIri } from '../../../features/study/utils/getStudyType';
import PlotStateBadge from '../../Common/StatusElement/PlotStateBadge';
import TableCellHeader from '../../Dashboard/TableCellHeader';
import './warningRDVTab.scss';

function WarningRDVTab(): JSX.Element {
  const dispatch = useAppDispatch();
  const { procedures } = useProcedures();
  usePloStudyStatus();
  const [orderBy, setOrderBy] = useState<string>('');
  const [order, setOrder] = useState<Order>('asc');
  const { idIri } = useAppSelector(getStudyParamsState);

  const headerCell = [
    { title: 'Date', rowSpan: 2 },
    { title: 'Opportunité', rowSpan: 2 },
    { title: 'Ville', rowSpan: 2 },
    { title: 'Contact', rowSpan: 2 },
    { title: 'Action', rowSpan: 2 },
    { title: 'Statut', rowSpan: 2 },
    { title: '', rowSpan: 2 },
  ];

  const handleRequestSort = (e: MouseEvent<unknown>, column: string) => {
    const isAsc = orderBy === column && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(column);
  };

  const handleDeleteProcedure = (item: Procedure) => {
    dispatch(procedureActions.procedureForActionSet(item));
    dispatch(modalsActions.procedureDelete(true));
  };

  const handleCellClick = (procedure: Procedure) => {
    if (idIri !== procedure.entity.idIri) {
      const type = getStudyTypeFromIdIri(procedure.entity.idIri);

      const studyParams: StudyParamsState = {
        type,
        idIri: procedure.entity.idIri,
        noCheckExisting: true,
        managmentLoader: false,
      };

      if (type === 'plotStudy') {
        dispatch(
          plotActions.fetchPlot.request({
            data: {
              lon: procedure.entity.lng as number,
              lat: procedure.entity.lat as number,
              department: procedure.entity.department as string,
              pdf: false,
            },
            studyParams,
          })
        );
      }

      // open right panel + select 'prospection tab'
      dispatch(panelsActions.studyContentShow('prospection'));
      dispatch(setStudyParamsThunk(studyParams));
    }
  };

  return (
    <div className="tab-warning-rdv">
      <div className="tab-inner">
        <h1>Mes alertes RDV</h1>
      </div>
      <div className="tab-body">
        <div className="body">
          <TableContainer>
            <Table className="table">
              <TableHead>
                <TableRow>
                  {headerCell.map((elt) => (
                    <TableCellHeader
                      key={nanoid()}
                      orderBy={orderBy}
                      order={order}
                      onRequestSort={handleRequestSort}
                      title={elt.title}
                      rowSpan={elt.rowSpan}
                    />
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {procedures.result &&
                  [...procedures.result]?.map((procedure: Procedure) => (
                    <TableRow className="row" key={nanoid()}>
                      <TableCell>{procedure.eventDate}</TableCell>
                      <TableCell
                        className="col-title"
                        onClick={() => handleCellClick(procedure)}
                      >
                        {procedureEntityNameDisplay(procedure)}
                      </TableCell>
                      <TableCell>{procedure.entity.city ?? '-'}</TableCell>
                      <TableCell>
                        {procedure.contact?.lastName && procedure.contact?.firstName
                          ? `${procedure.contact?.firstName ?? ''} ${
                              procedure.contact?.lastName ?? ''
                            }`
                          : procedure.contact?.name}
                      </TableCell>
                      <TableCell className="col-action">
                        {procedure.type ? (
                          <ProcedureType type={procedure.type} />
                        ) : (
                          '-'
                        )}
                      </TableCell>
                      <TableCell>
                        {procedure.entity.status ? (
                          <PlotStateBadge status={procedure.entity.status} />
                        ) : (
                          '-'
                        )}
                      </TableCell>
                      <TableCell className="col-delete">
                        <DeleteIcon
                          onClick={() => handleDeleteProcedure(procedure)}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
    </div>
  );
}

export default WarningRDVTab;
