import { useNavigate } from 'react-router';
import useApp from '../App/useApp';
import Logo from '../assets/images/logo.png';
import AgendaTab from '../components/Dashboard/AgendaTab';
// import styles
import DashboardTabs from '../components/Dashboard/DashboardTabs';
import DataOwnersTab from '../components/Dashboard/DataOwnersTab';
import DirectoryTab from '../components/Dashboard/DirectoryTab';
import FoldersTab from '../components/Dashboard/FoldersTab';
import ReportingTab from '../components/Dashboard/ReportingTab';
import '../components/filterSortBloc/filterSortBloc.scss';
import './styles/dashboard.scss';

export default function DashboardPage() {
  const { dashboardTab } = useApp();
  const navigate = useNavigate();
  const handleClick = () => {
    navigate('/');
  };

  return (
    <div className="dashboard-scroll">
      <div className="main-wrapper-dashboard">
        <div className="header-wrapper">
          <div className="header-col-left">
            <img src={Logo} alt="logo" />
          </div>
          <button onClick={handleClick}>Retour carte</button>
        </div>

        <DashboardTabs selectedTab={dashboardTab} />

        <div className="body-wrapper">
          {dashboardTab === 'directory' && <DirectoryTab />}
          {dashboardTab === 'agenda' && <AgendaTab />}
          {dashboardTab === 'reporting' && <ReportingTab />}
          {dashboardTab === 'folders' && <FoldersTab />}
          {dashboardTab === 'dataowner' && <DataOwnersTab />}
        </div>
      </div>
    </div>
  );
}
