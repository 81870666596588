import {
  fetchDatasNew,
  postEntity,
  updateEntity,
} from '../../../services/axiosFiles/genericCrud';
import {
  contactApiToStoreParser,
  contactStoreToApiParser,
  contactsApiToStoreParser,
} from '../contactParser';

interface IfetchContactThunkParams {
  itemsPerPage?: number;
  page?: number;
  search?: string;
}

/**
 * Request users with filters or full
 * @param {FetchCriterias} params page, itemsPerPage and search pattern
 * @param {string|null} companyIdIri - company endpoint
 * @returns {Contact[]} contact list
 */
export const fetchContacts = async ({
  params,
  companyIdIri,
}: {
  params?: FetchCriterias;
  companyIdIri: string | null;
}) => {
  try {
    if (companyIdIri) {
      const result = await fetchDatasNew({
        endpoint: `${companyIdIri}/contacts`,
        config: {
          params,
        },
      });

      const parsedContacts = contactsApiToStoreParser(result['hydra:member']);

      return parsedContacts;
    } else {
      return Promise.reject({ status: 400, message: 'companyIdIri missing' });
    }
  } catch (error) {
    return Promise.reject(error);
  }
};
export const createContact = async (
  params: StudyContactForm,
  companyIdIri: string
) => {
  try {
    if (companyIdIri && params.firstName && params.lastName) {
      const result = await postEntity({
        endpoint: '/contacts',
        body: { ...params, company: companyIdIri },
      });

      const parsedResponse = contactApiToStoreParser(result);

      return parsedResponse;
    } else {
      return Promise.reject({
        status: 402,
        message: 'one or more params are missing',
      });
    }
  } catch (error) {
    return Promise.reject(error);
  }
};
export const updateContact = async (
  contactIdIri: string,
  params: StudyContactForm | Contact
) => {
  try {
    if (params.firstName && params.lastName) {
      const parseForApi = contactStoreToApiParser(params);

      const result = await updateEntity({
        idIri: contactIdIri,
        body: parseForApi,
      });

      const parsedResponse = contactApiToStoreParser(result);

      return parsedResponse;
    } else {
      return Promise.reject({
        status: 422,
        message: 'one or more params are missing',
      });
    }
  } catch (error) {
    return Promise.reject(error);
  }
};
