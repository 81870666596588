import { FormControlLabel, Switch } from '@mui/material';
import { ChangeEvent } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../App/store';
import { ReactComponent as Edit } from '../../../../assets/images/picto-edit.svg';
import { getAuthState } from '../../../../features/auth/authSlice';
import { modalsActions } from '../../../../features/modals/modalsSlice';
import companyUserUpdateThunk from '../../../../features/users/services/thunks/companyUserUpdateThunk';
import { userActions } from '../../../../features/users/usersSlice';

const updateManagerRole = (roles: UserRoleType[], value: boolean) => {
  const manager = 'ROLE_MANAGER';
  let r = [...roles];
  if (value) {
    if (!r.includes(manager)) {
      r.push(manager);
    }
  } else {
    r = r.filter((f) => f !== manager);
  }

  return r;
};

interface IFooterProps {
  user: User;
}

function Footer({ user }: IFooterProps) {
  const auth = useAppSelector(getAuthState);
  const dispatch = useAppDispatch();

  const handleActiveUserChange = () => {
    dispatch(
      companyUserUpdateThunk({
        userIdIri: user.idIri,
        userIsActive: !user.isActive,
      })
    );
  };

  const handleRoleUserChange = (
    e: ChangeEvent<HTMLInputElement>,
    value: boolean
  ) => {
    const roles = updateManagerRole(user.roles, value);

    dispatch(
      companyUserUpdateThunk({
        userIdIri: user.idIri,
        roles,
      })
    );
  };

  const handleEditUser = () => {
    dispatch(userActions.setSelectedUser(user));
    dispatch(userActions.setEditingUser(true));

    dispatch(modalsActions.addUser(true));
  };

  return (
    <div className="card-footer">
      <div className="card-footer-edit">
        <div className="switch">
          <FormControlLabel
            value="start"
            control={
              <Switch
                color="primary"
                checked={user.isActive}
                required
                onChange={handleActiveUserChange}
                name="check"
              />
            }
            label="compte"
            labelPlacement="start"
          />
        </div>
        <div className="icon">
          <Edit onClick={handleEditUser} />
        </div>
      </div>
      {
        <div className="card-footer-role">
          <p>Prospecteur</p>
          <Switch
            color="primary"
            checked={user.isManager}
            required
            onChange={handleRoleUserChange}
            name="check"
            disabled={auth.userIdIri === user.idIri}
          />
          <p>Manager</p>
        </div>
      }
    </div>
  );
}

export default Footer;
