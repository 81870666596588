import { memo } from 'react';
import GenericModal, {
  GenericModalActionEnum,
  GenericModalEnum,
} from '../../../../../components/Modals/GenericModal';
import deleteStudyProcedure from '../../../services/deleteStudyProcedure';
import { useRightPanelProcedureContext } from './utils/rightPanelProcedureContext';

function ProcedureDeleteConfirmationModal() {
  const { deleteId, setDeleteId, onDeleteSuccess } = useRightPanelProcedureContext();

  const handleCancel = () => setDeleteId(null);

  const handleOkCallback = async () => {
    try {
      if (deleteId) {
        await deleteStudyProcedure(deleteId);
        onDeleteSuccess();
      }
    } catch (error) {
      console.log('peux pas supprimer studyProcedure');
    }
  };

  if (deleteId === null) return null;

  return (
    <GenericModal
      title="Confirmation de suppression"
      actionType={GenericModalActionEnum.OKCANCEL}
      modalType={GenericModalEnum.WARNING}
      okButtonContent="Supprimer"
      okCallback={handleOkCallback}
      cancelCallback={handleCancel}
      closeModal={handleCancel}
    >
      <p>Voulez vous vraiment supprimer cette démarche entreprise?</p>
    </GenericModal>
  );
}

export default memo(ProcedureDeleteConfirmationModal);
