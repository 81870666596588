import { nanoid } from '@reduxjs/toolkit';
import { APIStatus } from '../../../services/axiosFiles/apiTypes';
import usePLU from '../../../shared/hooks/usePLU';
import PluError from '../pluLegend/PluError';
import PluOtherRow from './PluOtherRow';
import styles from './pluOther.module.scss';

interface IPluOtherContainerProps {
  data: PluOthers;
}

function PluOtherContainer({ data }: IPluOtherContainerProps) {
  const { pluOthersApiStatus } = usePLU();

  // only error handle because pending status disable tab display
  if (pluOthersApiStatus === APIStatus.REJECTED) {
    return <PluError />;
  }

  return (
    <div className={styles.pluOtherContainer}>
      {data.map((m) => (
        <div key={nanoid()}>
          <PluOtherRow cat={m} />
        </div>
      ))}
    </div>
  );
}

export default PluOtherContainer;
