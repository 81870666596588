import styles from './genericTab.module.scss';

interface IProps<T> {
  label: T;
  selected: boolean;
  onSelect: () => void;
  bigger?: boolean;
  dataCy?: string;
}
function GenericTab<T>({ label, selected, onSelect, bigger, dataCy }: IProps<T>) {
  return (
    <div
      className={`${styles.genericTab}  ${selected ? styles.selected : ''} ${bigger ? styles.bigger : ''}`}
      onClick={() => onSelect()}
      data-cy={dataCy}
    >
      <div className={styles.genericTabContent}>{label as string}</div>
    </div>
  );
}

export default GenericTab;
