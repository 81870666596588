import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { nanoid } from '@reduxjs/toolkit';
import { useEffect, useState } from 'react';
import PaginationCustom from '../../../../components/Common/PaginationCustom/PaginationCustom';
import TableRowFullColumn from '../../../../components/Common/TableRowFullColumn';
import { genericEntitySort } from '../../../../lib/sort/genericEntitySort';
import { sortableEntityParser } from '../../../../utils/sortableEntitiesParser';
import UserPlotStudiesTableRow from './UserPlotStudiesTableRow';
import { userPlotsHeaderCell } from './constants';

interface IUserPlotTableContainerProps {
  plotStudies: PlotStudies;
  sortKey: string;
  order: Order;
}
function UserPlotStudiesTableContainer({
  plotStudies,
  order,
  sortKey,
}: IUserPlotTableContainerProps): JSX.Element {
  const [indexPage, setIndexPage] = useState<number>(0);
  const [itemsPerPage, setItemsPerPage] = useState<number>(10);
  const [psWithSortValues, setPsWithSortValues] = useState<SortablePlotStudies>([]);

  const hasPagination = (plotStudies.length ?? 0) > itemsPerPage;

  useEffect(() => {
    const withSortKeys = plotStudies?.map((m) => {
      return sortableEntityParser(m, {
        city: m.city,
        plotId: m.plotId ?? null,
        folderName: m.parent?.name ?? null,
        status: m.status?.label ?? null,
        area: m.area ?? null,
        zone: m.zone ?? null,
        askingPrice: m.askingPrice ?? null,
        expectedRevenue: m.expectedRevenue ?? null,
        buildableArea: m.buildableArea ?? null,
        priority: m.priority ?? null,
        createdAt: m.createdAt ?? null,
      });
    });

    setPsWithSortValues(withSortKeys);
  }, [plotStudies]);

  const handleChangeIndex = (index: number): void => {
    setIndexPage(index);
  };

  const handleItemsPerPageChange = (e: ChangeEventCustom) => {
    setItemsPerPage(e.target.value);
  };

  return (
    <Table size="small">
      <TableHead>
        <TableRow>
          {userPlotsHeaderCell.map((elt) => (
            <TableCell key={nanoid()}>{elt.title}</TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {genericEntitySort(psWithSortValues, sortKey, order)
          ?.filter((f, i) => i >= indexPage && i < indexPage + itemsPerPage)
          .map((plot) => (
            <UserPlotStudiesTableRow
              key={nanoid()}
              plotStudy={plot.entity}
              folderName={plot.entity.parent?.name ?? 'Indéfini'}
            />
          ))}
        {hasPagination && (
          <TableRowFullColumn colSpan={12}>
            <PaginationCustom
              itemPerPageLabel="Parcelles par page"
              elementsQty={plotStudies?.length || 0}
              itemsPerPage={itemsPerPage}
              onItemsPerPageChange={handleItemsPerPageChange}
              onMinIndexTab={handleChangeIndex}
            />
          </TableRowFullColumn>
        )}
      </TableBody>
    </Table>
  );
}

export default UserPlotStudiesTableContainer;
