import { endOfMonth } from 'date-fns';

/**
 * Date from string parser
 * @param {string | null} value
 * @returns {Date | null} Date from string value
 */
export const createDateFromString = (value: string | null) => {
  if (!value) return null;
  return new Date(value);
};

/**
 * Check for no future Date
 * @param {Date} date date to check
 * @returns {Date} good date
 */
export const noFutureDate = (date: Date) => {
  return date > new Date() ? new Date() : date;
};

/**
 * Check start and end date and fix with end = endOfMonth if start > end
 * @param {DateFilterType} dates dates start and end to check
 * @returns {DateFilterType} Valid start end
 */
export const checkValidStartEnd = (dates: DateFilterType) => {
  const newDates = { ...dates };
  const start = createDateFromString(dates.start);
  const end = createDateFromString(dates.end);

  if (start && end && start > end) {
    const newEndDate = endOfMonth(start);
    newDates.end = noFutureDate(newEndDate).toISOString();
  }

  return newDates;
};

/**
 * Date difference calculation
 * @param date1 - Start date
 * @param date2 - End date
 * @returns string with s and ms result
 */
export function dateDiff(date1: Date, date2: Date): string {
  const differenceEnMillisecondes = date2.getTime() - date1.getTime();
  const secondes = Math.floor(differenceEnMillisecondes / 1000);
  const millisecondes = differenceEnMillisecondes % 1000;
  return `${secondes},${millisecondes} secondes`;
}
