import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../App/store';
import { GenericModalEnum } from '../../components/Modals/GenericModal';

const initialState: ModalsState = {
  urbaneaseIntro: true,
  signin: false,
  signup: false,
  sectorDelete: false,
  sectorEdit: false,
  folderDelete: false,
  folderEdit: false,
  addUser: false,
  addUserResult: {
    isEdit: false,
    isOpen: false,
  },
  passwordChangeResult: false,
  subscriptionLimit: false,
  companyChange: false,
  companySearch: false,
  procedureDelete: false,
  connectionProcess: false,
  advancedSearchResult: { status: false, context: null },
  contactSearch: false,
  multiPlotAdd: { status: false, plots: [] },
  recusiveAssignForSubfolder: false,
  pluDemand: false,
  plotDelete: false,
  readMore: { status: false, type: null },
  altimetryHelperModal: false,
  measureHelperModal: false,
  alert: { status: false, context: null, modalType: GenericModalEnum.INFO },
  announcement: false,
  urbaGptDelete: false,
  errorTokenUrbaGptModal: false,
  drawHelperModal: false,
  loadProspecDataConfirm: { display: false, result: null },
  expiredSubscriptionModal: false,
  authErrorModal: false,
  bodaccModal: false,
  studyContactModal: false,
  cesiumHelperModal: false,
};

const modalsSlice = createSlice({
  name: 'modals',
  initialState,
  reducers: {
    reset: () => initialState,
    signin: (state, action: PayloadAction<boolean>) => {
      state.signin = action.payload;
      state.urbaneaseIntro = false;
      state.signup = false;
      state.authErrorModal = false;
    },
    signup: (state, action: PayloadAction<boolean>) => {
      state.signup = action.payload;
      state.signin = false;
      state.urbaneaseIntro = false;
      state.authErrorModal = false;
    },
    urbaneaseIntro: (state, action: PayloadAction<boolean>) => {
      state.urbaneaseIntro = action.payload;
      if (action.payload) {
        state.signup = false;
        state.signin = false;
        state.authErrorModal = false;
      } else {
        state.signup = true;
      }
    },
    closeAllAuthModal: (state) => {
      state.urbaneaseIntro = false;
      state.signup = false;
      state.signin = false;
      state.authErrorModal = false;
    },
    authErrorModalOpen: (state) => {
      state.authErrorModal = true;
      state.connectionProcess = false;
      state.urbaneaseIntro = false;
      state.signup = false;
      state.signin = false;
    },
    authErrorModalClose: (state) => {
      state.authErrorModal = false;
      state.signin = true;
      state.connectionProcess = false;
      state.urbaneaseIntro = false;
      state.signup = false;
    },
    expiredSubscriptionModalOpen: (state) => {
      state.expiredSubscriptionModal = true;
      state.connectionProcess = false;
      state.urbaneaseIntro = false;
      state.signup = false;
      state.signin = false;
    },
    expiredSubscriptionModalClose: (state) => {
      state.expiredSubscriptionModal = false;
      state.urbaneaseIntro = true;
    },
    connectionProcess: (state, action: PayloadAction<boolean>) => {
      state.connectionProcess = action.payload;
      if (action.payload) {
        state.authErrorModal = false;
        state.signin = false;
        state.urbaneaseIntro = false;
        state.signup = false;
      }
    },
    sectorDelete: (state, action: PayloadAction<boolean>) => {
      state.sectorDelete = action.payload;
    },
    sectorEdit: (state, action: PayloadAction<boolean>) => {
      state.sectorEdit = action.payload;
    },
    folderDelete: (state, action: PayloadAction<boolean>) => {
      state.folderDelete = action.payload;
    },
    folderEdit: (state, action: PayloadAction<boolean>) => {
      state.folderEdit = action.payload;
    },
    addUser: (state, action: PayloadAction<boolean>) => {
      state.addUser = action.payload;
    },
    addUserResult: (
      state,
      action: PayloadAction<{
        isEdit: boolean;
        isOpen: boolean;
      }>
    ) => {
      state.addUserResult = action.payload;
    },
    passwordChangeResult: (state, action: PayloadAction<boolean>) => {
      state.passwordChangeResult = action.payload;
    },
    subscriptionLimit: (state, action: PayloadAction<boolean>) => {
      state.subscriptionLimit = action.payload;
    },
    companyChange: (state, action: PayloadAction<boolean>) => {
      state.companyChange = action.payload;
    },
    companySearch: (state, action: PayloadAction<boolean>) => {
      state.companySearch = action.payload;
    },
    procedureDelete: (state, action: PayloadAction<boolean>) => {
      state.procedureDelete = action.payload;
    },

    advancedSearchResult: (
      state,
      action: PayloadAction<AdvancedPlotSearchModal>
    ) => {
      state.advancedSearchResult = action.payload;
    },
    contactSearch: (state, action: PayloadAction<boolean>) => {
      state.contactSearch = action.payload;
    },
    multiPlotAdd: (
      state,
      action: PayloadAction<{
        status: boolean;
        plots: Omit<ICreatePlotStudy, 'folder' | 'responsable'>[] | null;
      }>
    ) => {
      state.multiPlotAdd.plots = action.payload.plots;
      state.multiPlotAdd.status = action.payload.status;
    },
    recusiveAssignForSubfolder: (state, action: PayloadAction<boolean>) => {
      state.recusiveAssignForSubfolder = action.payload;
    },
    pluDemand: (state, action: PayloadAction<boolean>) => {
      state.pluDemand = action.payload;
    },
    plotDelete: (state, action: PayloadAction<boolean>) => {
      state.plotDelete = action.payload;
    },
    readMore: (state, action: PayloadAction<ReadMoreModal>) => {
      state.readMore = action.payload;
    },
    drawHelperModal: (state, action: PayloadAction<boolean>) => {
      state.drawHelperModal = action.payload;
    },
    altimetryHelperModal: (state, action: PayloadAction<boolean>) => {
      state.altimetryHelperModal = action.payload;
    },
    measureHelperModal: (state, action: PayloadAction<boolean>) => {
      state.measureHelperModal = action.payload;
    },
    toolbarHelperModalReset: (state) => {
      state.drawHelperModal = false;
      state.measureHelperModal = false;
      state.altimetryHelperModal = false;
      state.cesiumHelperModal = false;
    },
    alert: (state, action: PayloadAction<AlertType>) => {
      state.alert = action.payload;
    },
    alertClose: (state) => {
      state.alert = {
        status: false,
        context: null,
        modalType: GenericModalEnum.INFO,
      };
    },
    announcement: (state, action: PayloadAction<boolean>) => {
      state.announcement = action.payload;
    },
    urbaGptDelete: (state, action: PayloadAction<boolean>) => {
      state.urbaGptDelete = action.payload;
    },
    errorTokenUrbaGptModal: (state, action: PayloadAction<boolean>) => {
      state.errorTokenUrbaGptModal = action.payload;
    },
    loadProspecDataConfirm: (
      state,
      action: PayloadAction<LoadProspecDataConfirmType>
    ) => {
      state.loadProspecDataConfirm = action.payload;
    },
    bodaccModal: (state, action: PayloadAction<boolean>) => {
      state.bodaccModal = action.payload;
    },
    studyContactModal: (state, action: PayloadAction<boolean>) => {
      state.studyContactModal = action.payload;
    },
    cesiumHelperModal: (state, action: PayloadAction<boolean>) => {
      state.cesiumHelperModal = action.payload;
    },
  },
});

export default modalsSlice.reducer;
export const modalsActions = modalsSlice.actions;
export const getModalsState = (state: RootState) => state.modals;
