import { useContext } from 'react';
import { searchTypeEnum } from '../../../App/contexts/InputTabsContext';
import { InputTabsContext } from '../../../App/Layout';
import GenericTabContainer from '../components/genericTab/GenericTabContainer';

const TAB_LABELS = [searchTypeEnum.Adresse, searchTypeEnum.Parcelle];
const buttons = ['Adresse', 'Parcelle'];

function Tabs() {
  const { inputTab, setInputTab } = useContext(InputTabsContext);

  return (
    <GenericTabContainer
      tabLabels={TAB_LABELS}
      selectedId={inputTab}
      onSelect={(id) => setInputTab(id)}
    />
  );
}

export default Tabs;
